import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import showdown from 'showdown'

export const CollaboratorsPageTemplate = ({ title, page_name, description, image, main_section, church_city, sos_children_village, doctor_no_border, nrc, math_science }) => {
  const converter = new showdown.Converter();
  const backgroundImage = typeof image === 'string' ? image : image.childImageSharp.fluid.src

  const image1 = typeof main_section.image_1 === 'string' ? main_section.image_1 : main_section.image_1.childImageSharp.fluid.src
  const image2 = typeof main_section.image_2 === 'string' ? main_section.image_2 : main_section.image_2.childImageSharp.fluid.src
  const image3 = typeof main_section.image_3 === 'string' ? main_section.image_3 : main_section.image_3.childImageSharp.fluid.src
  const image4 = typeof main_section.image_4 === 'string' ? main_section.image_4 : main_section.image_4.childImageSharp.fluid.src
  const image5 = typeof main_section.image_5 === 'string' ? main_section.image_5 : main_section.image_5.childImageSharp.fluid.src



  const [sections, setSections] = useState({
    main: true,
    church_city: false,
    sos_children_village: false,
    doctor_no_border: false,
    nrc: false,
    math_science: false,
    un_goal: false
  })

  const changeSection = newSection => {
    setSections({ main: false, church_city: false, sos_children_village: false, doctor_no_border: false, nrc: false, math_science: false, un_goal: false })
    setSections({ [newSection]: true })
  }

  const renderFooterAction = page_name => {
    const pages = {
      home: {
        display: 'Hjem',
        next: '/stiftelsen'
      },
      stiftelsen: {
        display: 'Stiftelsen',
        next: '/styret',
        prev: '/'
      },
      styret: {
        display: 'Styret',
        next: '/formal',
        prev: '/stiftelsen'
      },
      formal: {
        display: 'Formål',
        next: '/fn',
        prev: '/styret'
      },
      fn: {
        display: 'FNs bærekraftsmål',
        next: '/vedtekter',
        prev: '/formal'
      },
      vedtekter: {
        display: 'Vedtekter',
        next: '/samarbeidspartnere',
        prev: '/fn'
      },
      samarbeidspartnere: {
        display: 'Samarbeidspartnere',
        next: '/stiftelsen',
        prev: '/vedtekter'
      },
    }

    const page = pages[page_name];

    return (
      <div className='footer-action'>
        <Link to={page.prev}  disabled={page.prev == undefined}  className={ `prev ${ page.prev == undefined ? 'disabled' : '' }` } >
          {"<  "}
        </Link>

        <span>{ page.display }</span>

        <Link to={page.next} disabled={page.next == undefined}  className={ `next ${ page.next == undefined ? 'disabled' : '' }` }>
          {"  >"}
        </Link>
      </div>
    )
  }

  return (
    <main
      id="index"
      className="foundation collaborators"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
      </Helmet>
      <Navbar page_name={page_name} />

      <section className="hero is-fullheight collaborators">
        <div className="mt-12">
          <div className="container">
            <div>
              <div className="main-section content no-border-bottom scrollable-container">
                <div className="columns">
                  <div className="column is-two-quarters">
                    <aside className="menu">
                      <ul className="menu-list">
                        <li style={{ marginBottom: "5px" }}>
                          <a
                            className={
                              sections.sos_children_village ? "is-active" : ""
                            }
                            onClick={() =>
                              changeSection("sos_children_village")
                            }
                          >
                            SOS-Barnebyer
                          </a>
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                          <a
                            className={
                              sections.doctor_no_border ? "is-active" : ""
                            }
                            onClick={() => changeSection("doctor_no_border")}
                          >
                            Leger uten grenser
                          </a>
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                          <a
                            className={sections.church_city ? "is-active" : ""}
                            onClick={() => changeSection("church_city")}
                          >
                            Kirkens Bymisjon
                          </a>
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                          <a
                            className={sections.nrc ? "is-active" : ""}
                            onClick={() => changeSection("nrc")}
                          >
                            Flyktninghjelpen
                          </a>
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                          <a
                            className={sections.math_science ? "is-active" : ""}
                            onClick={() => changeSection("math_science")}
                          >
                            Universitetet i Stavanger
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>

                  <div
                    className={`column is-two-fifths ${
                      sections.main == true ? "" : "hidden"
                    }`}
                  >
                    <h1 className="partners">Samarbeidspartnere</h1>

                    <div className="columns is-mobile">
                      <div className="column">
                        <img src={image1} />
                      </div>
                    </div>

                    <div className="columns is-mobile">
                      <div className="column">
                        <img src={image2} />
                      </div>
                    </div>

                    <div className="columns is-mobile">
                      <div className="column">
                        <img src={image3} />
                      </div>
                    </div>
                    
                    <div className="columns is-mobile">
                      <div className="column is-half-mobile">
                        <img src={image4} />
                      </div>
                      <div className="column is-half-mobile">
                        <img src={image5} />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`column is-three-fifths ${
                      sections.sos_children_village == true ? "" : "hidden"
                    }`}
                  >
                    <h1 className="partners">SOS-Barnebyer</h1>
                    <div className="no-border-bottom content has-text-white">
                      <h2>
                      SOS-Barnebyer er en internasjonal profesjonsorganisasjon med 70 års erfaring i å sikre barn den
                      omsorgen de trenger for å vokse opp og utvikle seg til selvstendige voksne. Målgruppen er barn som har
                      mistet eller lever med stor risiko for å miste omsorgen fra familien sin.
                      </h2>


                      <ul className="partner-bullets">
                        <li className="partner-bullet-item" style={{textDecoration: 'underline'}}>3 hovedområder for å sikre nødvendig omsorg:</li>
                        <li className="partner-bullet-item">Styrking av familier i krise.</li>
                        <li className="partner-bullet-item">Etablering av nye, trygge familier til barn som trenger det.</li>
                        <li className="partner-bullet-item">Myndighetspåvirkining for å ha nødvendig samfunnsstruktur og beskyttelse.</li>
                      </ul>

                      <h2>Stiftelsen har samarbeidet med SOS-barnebyer siden 2007 og vesentlig på åremål fra 2012.</h2>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year">2018:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">3-årig støtte tils. kr 1,5 mill. til SOS BB, Internasjonal sitt Implementeringsfond for Colombia.</p>
                          <p className="collaborator-detail-body">
                          Del av Bærekraftstrategi som medvirker til at SOS BB, Colombia kan få eierskap og evne til selv å drifte alle barnebyene og familieprogrammene i landet. Fredsavtale med FARC og fredspris til Santos i 2018 ga mer håp. I Årsmelding 2019 fra SOS BB, Norge står det at SOS BB Colombia fra 2020 selv kan finansiere sine programmer.

                        </p>
                        </p>
                      </div>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year">2015:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">3-årig startstøtte tils.kr 2,78 millioner for å fremskynde bærekraftstrategien i Colombia.</p>
                          <p className="collaborator-detail-body">
                          To styremedlemmer av ÅLS-styret dro til Bogota og rapporterte om vesentlig økt statlig finansiering og deltagelse i programmer samt mange flere nasjonale fadderskap.
                        </p>
                        </p>
                      </div>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year">2012:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">
                          3-årig støtte årlig tils. 45 000 USD til Familiestyrkningsprogrammet i slummen i Cali, Colombia.
                          </p>
                          <p className="collaborator-detail-body">
                          Forebyggende virksomhet. Rapport om utvidet førskole med mat og diverse opplæring av barnas foreldre med særlig vekt på kvinnerettigheter. Før støtte ble gitt hadde vi besøkt lokalitetene.
                        </p>
                        </p>
                      </div>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year">2007:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">
                          Engangsstøtte kr 1,23 mill. til bygging av 2 familiehus i ny SOS barneby i Cali, Colombia.
                          </p>
                          <p className="collaborator-detail-body">
                          Stiftelsen representert av familien Lærdal deltok
                            ved åpningen av barne byen i mai 2010.
                            Stiftelsens navn står på to husplater som takk for
                            gaven. Cali ble barneby nr 500 i verden.
                        </p>
                        </p>
                      </div>

                      <p>
                      I tillegg til div. programstøtte har SSM støttet SOS BB ad hoc med kr. 0,6 mill ved naturkatastrofer i nærheten av barnebyer og to ganger ved akutt flyktningeproblematikk i Colombia kr. 0,3mill.

                      </p>
                    </div>

                    <div className="link-section">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(sos_children_village.link),
                        }}
                      ></div>
                    </div>
                  </div>

                  <div
                    className={`column is-three-fifths ${
                      sections.doctor_no_border == true ? "" : "hidden"
                    }`}
                  >
                    <h1 className="partners">Leger uten grenser</h1>
                    <div className="no-border-bottom content has-text-white">
                      <h2>
                      Leger uten Grenser er en nøytral/uavhengig medisinsk hjelpeorganisasjon som redder liv og lindrer nød i over 70 land. 
                      I flere tiår har MSF rykket ut dit behovet for medisinsk hjelp er størst og hjulpet dem som trenger det mest.
                       Å fortelle om/synliggjøre kriser- som glemmes av det internasjonale samfunnet - kjennetegner også MSF.                   
                      </h2>
                      <h2>
                      SSM og MSF har samarbeidet fra 2014. På åremål fra 2016.

                      </h2>
  
                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year">2020:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">2-årig støtte øremerket til vaksinasjon og underernæring.</p>
                          <p className="collaborator-detail-body">
                          Årlig støttebeløp er på 1 million kroner, som i 2020 brukes til behandling av svært underernærte barn i Maiduguri, Nigeria.
                        </p>
                        </p>
                      </div>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year">2018:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">3-årig støtte øremerket til distrikthelsesentre i Mende, Kenema, Sierra Leone. </p>
                          <p className="collaborator-detail-body">
                          Årlig støtte på 500 000 kroner. I februar 2020 var to av SSMs styremedlemmer på feltbesøk i Kenema.
                        </p>
                        </p>
                      </div>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year">2016:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">3-årig støtte øremerket til mødre/barn/fødeklinikk i Shatila, Beirut, Libanon.</p>
                          <p className="collaborator-detail-body">
                          Årlig støtte på 500 000 kroner. SSMs styreformann var på feltbesøk til Beirut og Bekaadalen i 2019.
                        </p>
                        </p>
                      </div>

                      <p>
                      I tillegg har Leger Uten Grenser fått akutt nødhjelpsstøtte ved 5 anledninger siden 2014, på til sammen 600 000 kroner. Støtten har gått til ebolarespons i Sierra Leone, arbeid med båtflyktninger i Middelhavet, rohingyaene i flyktningleire i Bangladesh, syklonrespons i sørlige Afrika, samt støtte i forbindelse med at bombing av et sykehus i Afghanistan.
                      </p>
                    </div>

                    <div className="link-section">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(doctor_no_border.link),
                        }}
                      ></div>
                    </div>
                  </div>

                  <div
                    className={`column is-three-fifths ${
                      sections.church_city == true ? "" : "hidden"
                    }`}
                  >
                    <h1 className="partners">Kirkens Bymisjon, Rogaland (KBR)</h1>
                    <div className="no-border-bottom content has-text-white">
                      <h2>
                      Kirkens Bymisjon er en åpen og inkluderende ideell organisasjon som arbeider blant mennesker som strever med hverdagen på ulike måter. KBR sitt arbeid blant barn som SSM har støttet har vært basert på respekt, rettferdighet og omsorg. Unge pårørende som lever/har levd i hjem med vanskelige leveforhold har vært særlig tilgodesett.
                      </h2>

                      <h2>
                      Stiftelsen har støttet både prosjekt og drift i Kirkens Bymisjon fra 2009.
                      </h2>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year-double">2018-2019:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">
                          Generell driftstøtte tils. kr 1,0 mill. i disse årene.
                          </p>
                          <p className="collaborator-detail-body">
                        </p>
                        </p>
                      </div>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year-double">2016-2017:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">
                          Støtte til Barn og Ungdom ikke øremerket enkeltprosjekt tils. Kr  1,43 mill.
                          </p>
                          <p className="collaborator-detail-body">
                        </p>
                        </p>
                      </div>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year-double">2014-2016:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">
                          Lønn i  2år til prosjektleder Ventilene tils. kr 1, 23 mill.
                          </p>
                          <p className="collaborator-detail-body">
                        </p>
                        </p>
                      </div>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year-double">2009-2013:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">
                          Driftstilskudd tils. kr 237 500 til sommerleir på Alsvik i regi av Ventilene.
                          </p>
                          <p className="collaborator-detail-body">
                        </p>
                        </p>
                      </div>

                      <p>
                      Kartleggingsprosjekt under utarbeidelse for mulig støtte i 2020: «Særlig utsatte barn»

                      </p>
                    </div>

                    <div className="link-section">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(church_city.link),
                        }}
                      ></div>
                    </div>
                  </div>

                  <div
                    className={`column is-three-fifths ${
                      sections.nrc == true ? "" : "hidden"
                    }`}
                  >
                    <h1 className="partners">Flyktninghjelpen</h1>
                    <div className="no-border-bottom content has-text-white">
                      <h2>
                      Flyktninghjelpen (NRC) er en uavhengig norsk humanitær organisasjon som hjelper mennesker på flukt over hele verden og som har et nært samarbeid med FN og andre nasjonale og internasjonale organisasjoner. 
                      Av de rundt 15 000 humanitære som jobber for NRC er den store majoriteten nasjonalt ansatte med god kjennskap til den lokale konteksten.
                      </h2>

                      <h2>SSM vektlegger NRCs ekspertise og engasjement i over 30 land samt deres evne til å nå ut dit nøden er størst.</h2>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year">2020:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">Nødhjelpstøtte tils.  kr 200 000 til NCRs oppskalering av COVID-19 beredskap-såpe/rent vann.</p>
                          <p className="collaborator-detail-body">
                            
                        </p>
                        </p>
                      </div>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year">2019:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">Inngått 3-årig samarbeidsavtale fra 2020 på tils. kr 1,5 mill. fordelt på to separate prosjekter.</p>
                          <p className="collaborator-detail-body">
                            Prosjekt nr 1: 1 ½ års støtte tils. kr 750 000 til delfinansiering av skoleprosjektet «lnstitutio-nalizing the Better Learning. Program (BLP) in Gaza. 
                            Hovedsponsor er den nederlandske stiftelsen Porticus
                        </p>
                        </p>
                      </div>

                      <div className="collaborator-detail-block">
                        <p className="collaborator-detail-year">2018:</p>
                        <p className="collaborator-detail-content">
                          <p className="collaborator-detail-title">3 måneders pilotstøtteprosjekt tils kr 0,15 mill. til barn med skolevegring i Gaza.</p>
                          <p className="collaborator-detail-body"></p>
                        
                        </p>
                      </div>

                    </div>

                    <div className="link-section">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(nrc.link),
                        }}
                      ></div>
                    </div>
                  </div>

                  <div
                    className={`column is-three-fifths ${
                      sections.math_science == true ? "" : "hidden"
                    }`}
                  >
                    <h1 className="partners-small">
                    Institutt for Kjemi, Biovitenskap og Miljøteknologi,<br></br>
                                         Tek.-nat . Fakultetet, Universitetet i Stavanger

                    </h1>
                    <div className="no-border-bottom content has-text-white">

                      <p>
                      For å stimulere til forskning på rent vann ved UiS, spesielt i et U-lands sammenheng
                       bevilget SSM i desember 2011 tils. kr 4,0 mill. til basalforskniing innen vannrensing
                        til prosjektet: 
                      </p>
                      <p style={{textAlign: 'center'}}>
                        <strong>
                        «Nanomaterials in Aquatic Environments and Public Health»
                        </strong>
                      </p>

                      <p>
                       Forutsetningen for SSM sin støtte var at det kvalifiserte for gaveforsterkningsmidler fra Staten som på det tidspunktet var + 25%, dvs at UiS totalt ville få Kr 5,0 mill. som etter planen skulle brukes til PhD i 3 år og Post Doc lønn i inntil 1 ½ år.
                      </p>

                      <p>
                      Prosjektet kom imidlertid bare til planleggingsstadiet pga uforutsette bemanningsproblemer. Som en omforent løsning for bruk av gavemidlene utformet førsteamanuensis Roald Kommedal i 2015 en ny prosjektbeskrivelse som ble godkjent:
                      </p>
                      <p style={{textAlign: 'center'}}>    
                        <strong>«Holistic wastewater treatment concept for integrated water and resource recovery»</strong>
                      </p>


                      <p>
                      Prosjektet har vært aktivt siden 2017 med to forskere delfinansiert sålangt og ført til flere artikler 

                      </p>
                    </div>

                    <div className="link-section">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(math_science.link),
                        }}
                      ></div>
                    </div>
                  </div>



                  <div className="column  "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>{renderFooterAction(page_name)}</footer>
    </main>
  );
}

CollaboratorsPageTemplate.propTypes = {
  image: PropTypes.object.isRequired,
  page_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  main_section: PropTypes.object.isRequired,
  sos_children_village: PropTypes.object.isRequired,
  church_city: PropTypes.object.isRequired,
  doctor_no_border: PropTypes.object.isRequired,
  nrc: PropTypes.object.isRequired,
  math_science: PropTypes.object.isRequired,
  un_goal: PropTypes.object.isRequired,
}

const CollaboratorsPage = ({ data }) => {
  console.log(data);
  const { markdownRemark: post } = data

  return (
    <Layout>
      <CollaboratorsPageTemplate
        page_name={post.frontmatter.page_name}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        main_section={post.frontmatter.main_section}
        sos_children_village={post.frontmatter.sos_children_village}
        doctor_no_border={post.frontmatter.doctor_no_border}
        church_city={post.frontmatter.church_city}
        nrc={post.frontmatter.nrc}
        math_science={post.frontmatter.math_science}
        un_goal={post.frontmatter.un_goal}
      />
    </Layout>
  )
}

CollaboratorsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CollaboratorsPage

export const CollaboratorsPageQuery = graphql`
  query CollaboratorsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        main_section {
          image_1 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_2 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_3 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_4 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_5 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        page_name
        title
        description
        sos_children_village {
          content
          link
        }
        doctor_no_border {
          content
          link
        }
        nrc {
          content
          link
        }
        math_science {
          content
          link
        }
        un_goal {
          image_1 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_2 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_3 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_4 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_5 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_6 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        church_city {
          content
          link
        }
      }
    }
  }
`
